import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachPreviewList, AttachSearchTableRow } from '../../model/globalSearch';
import ButtonText from '../ui/buttons/ButtonText';
import ScrollBox from '../ui/containers/ScrollBox';
import Highlight from '../ui/highlights/Highlight';
import PopupCommonExternal from '../ui/popups/PopupCommonExternal';
import TableFooter from '../ui/tables/TableFooter';
import PopupPreviewFileDetail from './PopupPreviewFileDetail';

interface IProps {
	data: AttachPreviewList;
	allText: AttachSearchTableRow;
}

interface ParsedResult {
	key: string;
	text: string;
	page: number;
}

export default function PopupPreviewFile({ data, allText }: IProps) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleChangePage = (e: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	function parseText(inputText: string): ParsedResult[] {
		// 결과를 담을 리스트
		const result: ParsedResult[] = [];

		// 페이지 번호 추출 정규식
		const pageRegex = /..PAGE:(\d+)/g;

		// 페이지 번호 추출
		let currentPage = -1; // 초기값을 -1로 설정
		let match: RegExpExecArray | null;

		// eslint-disable-next-line no-cond-assign
		while ((match = pageRegex.exec(inputText)) !== null) {
			currentPage = parseInt(match[1], 10);
		}

		// @@@@@@ 기준으로 분리
		const parts = inputText.split('@@@@@@');

		// 분리된 텍스트를 리스트에 담기
		parts.forEach((part, index) => {
			// 인덱스가 0이면 key를 '000'으로 설정
			const key = index === 0 ? '000' : part.slice(0, 3);

			// 텍스트에서 페이지 번호 추출 (page 번호가 변경되는 경우 반영)
			const pageMatch = /..PAGE:(\d+)/.exec(part);
			if (pageMatch) {
				currentPage = parseInt(pageMatch[1], 10);
			}

			// ..PAGE:숫자 형태의 텍스트를 모두 제거
			const cleanedText = part.replace(/..PAGE:\d+/g, '').trim();

			// key와 텍스트 저장 (인덱스가 0일 경우 @@@@@@ 앞부분도 포함)
			result.push({
				key,
				text: index === 0 ? cleanedText : cleanedText.slice(3), // key 뒤로 잘라냄
				page: currentPage,
			});
		});

		return result.filter(item => item.text.includes('<mark>'));
	}

	const visibleRows = useMemo(() => {
		const resultArr = parseText(data.file_contents_summary);

		return {
			count: resultArr.length,
			rows: resultArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		};
	}, [page, rowsPerPage]);

	const render = useMemo(() => {
		return (
			<ScrollBox>
				<section className='flex flex-col gap-5'>
					<div>
						<h2 className='text-2xl font-bold text-left'>{t('MSG_ID_68')}</h2>
					</div>
					<ul className='grid gap-5'>
						{visibleRows.count > 0
							? visibleRows.rows.map(item => (
									<li key={item.key} className='flex items-center gap-6'>
										<div className='text-left'>
											{item.page !== -1 && <span>{item.page}.</span>}
											<Highlight text={item.text} pattern='' />
										</div>
										<PopupPreviewFileDetail data={allText} lineText={item.text} />
									</li>
								))
							: t('no_such_data')}
					</ul>
					<div className='grid justify-right'>
						<TableFooter
							rowsPerPageOptions={[10, 20, 30, 50]}
							showFirstButton
							showLastButton
							count={visibleRows.count}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</div>
				</section>
			</ScrollBox>
		);
	}, [page, rowsPerPage]);
	return (
		<>
			<ButtonText text={t('MSG_ID_68')} size='small' variant='outlined' onClick={handleOpen} />
			<PopupCommonExternal open={open} msg={render} onClose={handleClose} onCancel={handleClose} />
		</>
	);
}
