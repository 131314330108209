import { FormikValues } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalSearchList } from '../../../context/GlobalSearchContext';
import { useAlert } from '../../../hooks/useAlert';
import { LegalType } from '../../../model/common';
import { GlobalSearchTableRow } from '../../../model/globalSearch';
import SysConfigStore from '../../../store/common/SysConfigStore';
import { getLegalLinkMap, openToAdele } from '../../../util/commonUtils';
import ButtonFront from '../../ui/buttons/ButtonFront';
import ButtonText from '../../ui/buttons/ButtonText';
import FileChip from '../../ui/chips/FileChip';
import LabelChip from '../../ui/chips/LabelChip';
import StateChip from '../../ui/chips/StateChip';
import Highlight from '../../ui/highlights/Highlight';
import CounselIcon from '../../ui/icons/apps/custom/sk/CounselIcon';
import TableFooter from '../../ui/tables/TableFooter';
import ItemSearchField from '../ItemSearchField';
import PopupPreviewComment from '../PopupPreviewComment';
import PopupPreviewFile from '../PopupPreviewFile';
import PopupPreviewSmail from '../PopupPreviewSmail';

interface IProps {
	data: GlobalSearchTableRow;
	formik: FormikValues;
}

export default function CounselPanel({ data, formik }: IProps) {
	const { t } = useTranslation();
	const { labelText } = SysConfigStore();
	const { searchParams, setSearchParams } = useGlobalSearchList();
	const [snackbar] = useAlert();
	const handleNavigate = async (id: number, type: LegalType) => {
		const { success, url, msg } = await getLegalLinkMap(type, id);
		if (success) {
			openToAdele(url);
		} else {
			snackbar(msg, 'error');
		}
	};
	const handleChangePage = (e: unknown, newPage: number) => {
		setSearchParams(prev => ({ ...prev, currentPage: newPage }));
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchParams(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value, 10), currentPage: 0 }));
	};
	return (
		<>
			<p className='font-bold'>
				{t('counsel')} {data.counselCnt}
			</p>
			<ul className='col-span-9 grid gap-12 [&>li]:pb-12 [&>li:last-of-type]:pb-0 [&>li]:border-b [&>li:last-child]:border-none'>
				{data &&
					data.counselList.map(row => (
						<li key={row.id} className='grid gap-6'>
							{formik.values.isTitle && (
								<section>
									<div className='flex items-center gap-2'>
										<CounselIcon className='text-neutral-500' />
										<ButtonText
											className='text-lg font-bold'
											onClick={() => handleNavigate(row.id, 'COUNSEL')}
										>
											<Highlight text={row.title} pattern='' />
										</ButtonText>
									</div>
								</section>
							)}
							<section className='grid grid-cols-4 gap-6'>
								{formik.values.isDescription && (
									<>
										<ItemSearchField title={t('dispute_label_management_company')}>
											<p>
												<Highlight text={row.company} pattern='' />
											</p>
										</ItemSearchField>
										<ItemSearchField title={t('label_situation')}>
											<p>
												<StateChip text={row.status} />
											</p>
										</ItemSearchField>
										<ItemSearchField title={t('label_legal_type')}>
											<div className='flex flex-wrap items-center gap-1'>
												{row.tags !== ''
													? row.tags.split('|').map((item, idx) => (
															/* eslint-disable-next-line react/no-array-index-key */
															<li key={idx} className='flex gap-2'>
																<LabelChip
																	id='label-1'
																	disabled
																	tag={{
																		id: 1,
																		name: <Highlight text={item} pattern='' />,
																		value: item,
																		color: row.tags_color.split('|')[idx],
																		type: 'public',
																		extended_type: 'DEFAULT',
																		creator_id: 1,
																	}}
																/>
															</li>
														))
													: ''}
											</div>
										</ItemSearchField>
										<ItemSearchField title={t('label_external_lawyer')}>
											<p>
												{row.external_lawyer_name !== ''
													? row.external_lawyer_name.split('|').map((item, idx) => (
															/* eslint-disable-next-line react/no-array-index-key */
															<li key={idx} className='flex gap-2'>
																<Highlight
																	text={`${item} / ${row.external_lawyer_email.split('|')[idx]}`}
																	pattern=''
																/>
															</li>
														))
													: ''}
											</p>
										</ItemSearchField>
										<ItemSearchField
											className='col-span-4 items-start'
											title={t('dispute_label_facts')}
											value={row.facts_content_text}
										>
											<div className='flex-1'>
												<Highlight text={row.facts_content_text} pattern='' />
											</div>
										</ItemSearchField>
										<ItemSearchField
											className='col-span-4 items-start'
											title={t('dispute_guide_request_explain')}
											value={row.request}
										>
											<div className='flex-1'>
												<Highlight text={row.request} pattern='' />
											</div>
										</ItemSearchField>
										<ItemSearchField
											className='col-span-4 items-start'
											title={t('cmmn_label_answer')}
											value={row.answered}
										>
											<div className='flex-1'>
												<Highlight text={row.answered} pattern='' />
											</div>
										</ItemSearchField>
									</>
								)}

								{formik.values.isDescription &&
									data.commentList
										.filter(item => item.comment_type === 'counsel_history')
										.filter(item => item.counsel_id === row.id).length > 0 && (
										<ItemSearchField
											className='col-span-4 items-start'
											title={t('global_search_history_comment')}
										>
											<ul className='flex-1 grid grid-cols-2 gap-8'>
												{data.commentList
													.filter(item => item.comment_type === 'counsel_history')
													.filter(item => item.counsel_id === row.id)
													.map(item => (
														<li
															key={item.pk}
															className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'
														>
															<div className='flex-1'>
																<Highlight
																	text={item.comment.replace(/<[^>]*>/g, '')}
																	pattern=''
																/>
															</div>
															<PopupPreviewComment data={item} />
														</li>
													))}
											</ul>
										</ItemSearchField>
									)}

								{formik.values.isFile &&
									data.attachList
										.filter(item => item.doc_type === 'bcattachment')
										.filter(item => item.counsel_id === row.id).length > 0 && (
										<ItemSearchField
											className='col-span-4 items-center'
											title={t('global_search_history_file')}
										>
											<ul className='flex-1 flex flex-wrap items-center gap-2'>
												{data.attachList
													.filter(item => item.doc_type === 'bcattachment')
													.filter(item => item.counsel_id === row.id)
													.map(item => (
														<li key={item.pk} className='flex gap-2'>
															<FileChip
																text={<Highlight text={item.file_name} pattern='' />}
																disabled
															/>
															<PopupPreviewFile
																data={
																	data.attachPreviewList.find(
																		attach => attach.pk === item.pk,
																	) ?? { pk: '', file_contents_summary: '' }
																}
																allText={item}
															/>
														</li>
													))}
											</ul>
										</ItemSearchField>
									)}

								{formik.values.isDescription &&
									data.commentList
										.filter(item => item.comment_type === 'blegal_dept_share')
										.filter(item => item.counsel_id === row.id).length > 0 && (
										<ItemSearchField
											className='col-span-4 items-start'
											title={labelText('cmmn_label_legal_dept_share')}
										>
											<ul className='flex-1 grid grid-cols-2 gap-8'>
												{data.commentList
													.filter(item => item.comment_type === 'blegal_dept_share')
													.filter(item => item.counsel_id === row.id)
													.map(item => (
														<li
															key={item.pk}
															className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'
														>
															<div className='flex-1'>
																<Highlight
																	text={item.comment.replace(/<[^>]*>/g, '')}
																	pattern=''
																/>
															</div>
															<PopupPreviewComment data={item} />
														</li>
													))}
											</ul>
										</ItemSearchField>
									)}

								{formik.values.isFile &&
									data.attachList
										.filter(item => item.doc_type === 'blegal_dept_files')
										.filter(item => item.counsel_id === row.id).length > 0 && (
										<ItemSearchField
											className='col-span-4 items-center'
											title={labelText('global_search_legal_file')}
										>
											<ul className='flex-1 flex flex-wrap items-center gap-2'>
												{data.attachList
													.filter(item => item.doc_type === 'blegal_dept_files')
													.filter(item => item.counsel_id === row.id)
													.map(item => (
														<li key={item.pk} className='flex gap-2'>
															<FileChip
																text={<Highlight text={item.file_name} pattern='' />}
																disabled
															/>
															<PopupPreviewFile
																data={
																	data.attachPreviewList.find(
																		attach => attach.pk === item.pk,
																	) ?? { pk: '', file_contents_summary: '' }
																}
																allText={item}
															/>
														</li>
													))}
											</ul>
										</ItemSearchField>
									)}

								{formik.values.isDescription &&
									data.commentList
										.filter(item => item.comment_type === 'smail')
										.filter(item => item.counsel_id === row.id).length > 0 && (
										<ItemSearchField
											className='col-span-4 items-start flex flex-col'
											title={t('cmmn_label_smart_email')}
										>
											{data.commentList
												.filter(item => item.comment_type === 'smail')
												.filter(item => item.counsel_id === row.id)
												.map(item => (
													<div key={item.pk} className='flex gap-2'>
														<Highlight
															text={JSON.parse(item.comment).message_txt}
															pattern=''
														/>
														<PopupPreviewSmail data={item} />
													</div>
												))}
										</ItemSearchField>
									)}

								{formik.values.isFile &&
									data.attachList
										.filter(item => item.doc_type === 'cnsl_smail_file')
										.filter(item => item.counsel_id === row.id).length > 0 && (
										<ItemSearchField
											className='col-span-4 items-center'
											title={t('cmmn_label_smart_email_file')}
										>
											<ul className='flex-1 flex flex-wrap items-center gap-2'>
												{data.attachList
													.filter(item => item.doc_type === 'cnsl_smail_file')
													.filter(item => item.counsel_id === row.id)
													.map(item => (
														<li key={item.pk} className='flex gap-2'>
															<FileChip
																text={<Highlight text={item.file_name} pattern='' />}
																disabled
															/>
															<PopupPreviewFile
																data={
																	data.attachPreviewList.find(
																		attach => attach.pk === item.pk,
																	) ?? { pk: '', file_contents_summary: '' }
																}
																allText={item}
															/>
														</li>
													))}
											</ul>
										</ItemSearchField>
									)}
							</section>
						</li>
					))}
			</ul>
			{searchParams.searchCtg === 'ALL' ? (
				data.counselCnt !== 0 && (
					<div className='col-span-10 flex justify-end pt-12'>
						<ButtonFront
							text={t('global_search_see_all')}
							onClick={() =>
								setSearchParams(prev => ({
									...prev,
									searchCtg: 'COUNSEL',
								}))
							}
						/>
					</div>
				)
			) : (
				<TableFooter
					className='col-span-10 flex justify-end pt-12'
					rowsPerPageOptions={[10, 20, 30, 50]}
					showFirstButton
					showLastButton
					count={data.counselCnt}
					rowsPerPage={searchParams.rowsPerPage}
					page={searchParams.currentPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</>
	);
}
