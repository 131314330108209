import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatusCount } from '../../../../../api/dashboard';
import {
	contractSearchParams,
	counselSearchParams,
	lawsuitSearchParams,
} from '../../../../../constants/adeleSearchParams';
import { contractListUrl, counselListUrl, lawsuitListUrl } from '../../../../../constants/adeleUrl';
import { queryKey } from '../../../../../constants/queryKey';
import { useSubsidiary } from '../../../../../context/SubsidiaryContext';
import { useUser } from '../../../../../context/UserContext';
import SysConfigStore from '../../../../../store/common/SysConfigStore';
import { navigateToAdele } from '../../../../../util/commonUtils';
import StatusCardDashboard from './StatusCardDashboard';

export default function StatusDashboardDefault() {
	const { id: subsidiaryId } = useSubsidiary();
	const { data: statusCount } = useQuery([queryKey.dashboard, queryKey.workStatusCount, subsidiaryId], () =>
		getMainWorkStatusCount(subsidiaryId),
	);
	const { t } = useTranslation();
	const { isModuleEnabled, isComponentEnabled , labelText} = SysConfigStore();
	const { isViewer } = useUser();

	const handleNavigate = (
		url: string,
		workType: 'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC' | 'LAWSUIT',
		status: 'REVIEW' | 'COMPLETE' | 'REVIEW_AND_SIGN_AND_SEAL' | 'PROCESS' | 'DONE',
	) => {
		const paramsMap = {
			CONTRACT: contractSearchParams,
			COUNSEL: counselSearchParams,
			CONTRACT_ETC: contractSearchParams,
			LAWSUIT: lawsuitSearchParams,
		};

		if (['CONTRACT', 'CONTRACT_ETC'].includes(workType)) {
			sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: workType }));
		}
		if (workType === 'CONTRACT_ETC') workType = 'CONTRACT';
		sessionStorage.setItem(
			`${workType.toLowerCase()}_search_param`,
			JSON.stringify({
				...paramsMap[workType],
				status_check: `PROGRESS_STS_${status}`,
			}),
		);
		if (workType === 'LAWSUIT')
			sessionStorage.setItem(
				`lawsuit_search_param`,
				JSON.stringify({
					...paramsMap[workType],
					progress_status_search: status,
				}),
			);
		sessionStorage.setItem('from_dashboard_to_con_list', 'True');
		navigateToAdele(url);
	};

	const reviewItems = [
		{
			count: statusCount?.contract.review_count,
			label: t('MSG_ID_5'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT', 'REVIEW_AND_SIGN_AND_SEAL'),
		},
		{
			count: statusCount?.counsel.review_count,
			label: t('counsel_label_advice'),
			onClick: () => handleNavigate(counselListUrl, 'COUNSEL', 'REVIEW'),
			display: isModuleEnabled('COUNSEL_MANAGE'),
		},
		{
			count: statusCount?.contract_etc?.review_count,
			label: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'REVIEW_AND_SIGN_AND_SEAL'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') && isComponentEnabled('CMPNT298_SK_NET_CUSTOM_BF'),
		},
		{
			count: statusCount?.lawsuit?.review_count ? statusCount?.lawsuit?.review_count : 0,
			label: t('MSG_ID_1203'),
			onClick: () => handleNavigate(lawsuitListUrl, 'LAWSUIT', 'PROCESS'),
			display: isComponentEnabled('CMPNT184_DISPUTE_BF'),
		},
		{
			count: statusCount?.contract_etc?.review_count,
			label: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'REVIEW_AND_SIGN_AND_SEAL'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') && !isComponentEnabled('CMPNT298_SK_NET_CUSTOM_BF'),
		},
	];

	
	const completedItems = [
		{
			count: statusCount?.contract.completed_count,
			label: t('MSG_ID_5'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT', 'COMPLETE'),
		},
		{
			count: statusCount?.counsel.completed_count,
			label: t('counsel_label_advice'),
			onClick: () => handleNavigate(counselListUrl, 'COUNSEL', 'COMPLETE'),
			display: isModuleEnabled('COUNSEL_MANAGE'),
		},
		{
			count: statusCount?.contract_etc?.completed_count,
			label: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'COMPLETE'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') && isComponentEnabled('CMPNT298_SK_NET_CUSTOM_BF'),
		},
		{
			count: statusCount?.lawsuit?.completed_count ? statusCount?.lawsuit?.completed_count : 0,
			label: t('MSG_ID_1203'),
			onClick: () => handleNavigate(lawsuitListUrl, 'LAWSUIT', 'DONE'),
			display: isComponentEnabled('CMPNT184_DISPUTE_BF'),
		},
		{
			count: statusCount?.contract_etc?.completed_count,
			label: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'COMPLETE'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') && !isComponentEnabled('CMPNT298_SK_NET_CUSTOM_BF'),
		},
	];

	if (isModuleEnabled('VIEWER_HIDE_COUNSEL') && isViewer) {
		reviewItems.splice(1, 1);
		completedItems.splice(1, 1);
	}

	return (
		<>
			<StatusCardDashboard className='col-span-2' title={t('cmmn_label_ing_task')} items={reviewItems} />
			<StatusCardDashboard className={
				isComponentEnabled('CMPNT342_HIDE_MENU_SIGNSEAL_B')
				&& !isComponentEnabled('CMPNT163_DIGITAL_CERTIFICATE_BF') ?
					'col-span-4' :
					'col-span-2'
			} title={t('cmmn_label_completed_task')} items={completedItems} />
		</>
	);
}
