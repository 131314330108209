import _ from 'lodash';
import { useMemo } from 'react';

/**
 * @property text 원본 문자열
 * @property pattern 속한 문자열을 <mark> 태그로 트리구조 변경
 */
interface IProps {
	text: string;
	pattern: string;
}

export default function Highlight({ text, pattern }: IProps) {
	if (!text) return '';

	if (!pattern) {
		// 정규식을 이용해 <mark></mark> 태그 파싱
		const parts = text.split(/(<mark>.*?<\/mark>)/gi);

		return (
			<>
				{parts.map((part, index) => {
					if (part.startsWith('<mark>') && part.endsWith('</mark>')) {
						const markedText = part.slice(6, -7); // <mark>와 </mark>를 제거한 내용
						/* eslint-disable-next-line react/no-array-index-key */
						return <mark key={index}>{markedText}</mark>;
					}
					/* eslint-disable-next-line react/no-array-index-key */
					return <span key={index}>{part}</span>;
				})}
			</>
		);
	}

	const parts = useMemo(() => text.split(new RegExp(`(${_.escapeRegExp(pattern)})`, 'gi')), [text, pattern]);
	return parts.map(part =>
		part.toLowerCase() === pattern.toLowerCase() ? <mark key={_.uniqueId(part)}>{part}</mark> : part,
	);
}
