import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentSearchTableRow } from '../../model/globalSearch';
import TinyMCE from '../common/editor/TinyMCE';
import ButtonText from '../ui/buttons/ButtonText';
import PopupCommonExternal from '../ui/popups/PopupCommonExternal';

interface IProps {
	data?: CommentSearchTableRow;
}

export default function PopupPreviewSmail({ data }: IProps) {
	const { t } = useTranslation();
	const mailObj = data ? JSON.parse(data.comment) : '';

	const [open, setOpen] = useState(false);
	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);
	const formatNumberToDate = (num: number): string => {
		const numStr = num.toString(); // 숫자를 문자열로 변환

		// 문자열을 연도, 월, 일, 시간, 분, 초로 분리
		const year: string = numStr.substring(0, 4);
		const month: number = parseInt(numStr.substring(4, 6), 10) - 1; // 월은 0부터 시작하므로 1을 뺌
		const day: string = numStr.substring(6, 8);
		const hours: string = numStr.substring(8, 10);
		const minutes: string = numStr.substring(10, 12);
		const seconds: string = numStr.substring(12, 14);

		// Date 객체 생성
		const date = new Date(
			parseInt(year, 10),
			month,
			parseInt(day, 10),
			parseInt(hours, 10),
			parseInt(minutes, 10),
			parseInt(seconds, 10),
		);

		// 날짜 형식을 원하는 방식으로 변환 (예: YYYY-MM-DD HH:mm:ss)
		const formattedDate: string = `${year}-${numStr.substring(4, 6)}-${day} ${hours}:${minutes}:${seconds}`;

		return formattedDate;
	};
	const render = useMemo(() => {
		return (
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold text-left'>{mailObj.subject.replace(/<[^>]*>/g, '')}</h2>
				</div>
				<div className='flex items-center gap-4'>
					<p>{t('MSG_ID_889')}</p>
					<p>
						<b>{mailObj.to}</b>
					</p>
				</div>
				<div className='flex items-center gap-4'>
					<p>{t('MSG_ID_890')}</p>
					<p>
						<b>{mailObj.from}</b>
					</p>
				</div>
				<div className='flex items-center gap-4'>
					<p>{t('cmmn_label_reference')}</p>
					<p>
						<b>{mailObj.cc}</b>
					</p>
				</div>
				<div className='flex items-center gap-4'>
					<p>{t('cmmn_label_send_date')}</p>
					<p>
						<b>{mailObj.date}</b>
					</p>
				</div>
				<div className='p-3 border border-neutral-500 rounded-2xl bg-zinc-100'>
					<TinyMCE
						initialValue={mailObj.message}
						init={{ height: 200, menubar: false, toolbar: false, statusbar: false, readonly: true }}
						disabled
					/>
				</div>
			</section>
		);
	}, []);
	return (
		<>
			<ButtonText text={t('MSG_ID_68')} size='small' variant='outlined' onClick={handleOpen} />
			<PopupCommonExternal open={open} msg={render} onClose={handleClose} onCancel={handleClose} />
		</>
	);
}
