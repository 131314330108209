import Tabs from '@mui/material/Tabs';
import {useQuery} from '@tanstack/react-query';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {getMainWorkStatus} from '../../../../../api/dashboard';
import {queryKey} from '../../../../../constants/queryKey';
import {useSubsidiary} from '../../../../../context/SubsidiaryContext';
import {useUser} from '../../../../../context/UserContext';
import {DashboardWorkStatus} from '../../../../../model/api/dashboard';
import SysConfigStore from '../../../../../store/common/SysConfigStore';
import CardBox from '../../../../ui/containers/CardBox';
import DisputeIcon from '../../../../ui/icons/DisputeIcon';
import AllIcon from '../../../../ui/icons/apps/custom/sk/AllIcon';
import ContractIcon from '../../../../ui/icons/apps/custom/sk/ContractIcon';
import CounselIcon from '../../../../ui/icons/apps/custom/sk/CounselIcon';
import DocIcon from '../../../../ui/icons/apps/custom/sk/DocIcon';
import LawsuitIcon from '../../../../ui/icons/apps/custom/sk/LawsuitIcon';
import ReviewIcon from '../../../../ui/icons/apps/custom/sk/ReviewIcon';
import SignIcon from '../../../../ui/icons/apps/custom/sk/SignIcon';
import ListDashboard from './ListDashboard';
import TabButtonDashboard from './TabButtonDashboard';
import ClaimIcon from '../../../../ui/icons/apps/custom/sk/ClaimIcon';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, className = '', ...other} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function TabsDashboardOrder() {
    const {getSysConfigValue, isModuleEnabled, isComponentEnabled, labelText} = SysConfigStore();
    const {permission} = useUser();
    const [selectedOrderTab, setSelectedOrderTab] = React.useState(0);
    const {id: subsidiaryId} = useSubsidiary();
    const {t} = useTranslation();

    const headerMenuOrder = getSysConfigValue('HEADER_MENU_BAR_SORT');

    // \r\n, \n 등 줄바꿈 문자를 제거하고 앞뒤 공백을 정리한 후
    const cleanedMenuOrder = headerMenuOrder.replace(/[\r\n]/g, '').trim();
    // 쉼표 또는 공백을 기준으로 문자열을 배열로 변환
    let menuOrderArray = cleanedMenuOrder.split(/[, ]+/)
        .filter((item: string) => item); // 빈 문자열 제거
    menuOrderArray = menuOrderArray.filter((item:string, index:number) => menuOrderArray.indexOf(item) === index);

    const allTabs: [{ label: string; icon?: JSX.Element; type: string, menu_bar_value: string }, boolean][] = [
        [{label: '계약', icon: <ContractIcon/>, type: 'CONTRACT', menu_bar_value: 'contract'}, true],
        [{label: '자문', icon: <CounselIcon/>, type: 'COUNSEL', menu_bar_value: 'counsel'}, permission.DISPLAY_COUNSEL],
        [{
            label: t('MSG_ID_1203'),
            icon: <LawsuitIcon/>,
            type: 'LAWSUIT',
            menu_bar_value: 'lawsuit'

        }, permission.DISPLAY_LAWSUIT],
        [
            {
                label: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
                icon: <ReviewIcon/>,
                type: 'CONTRACT_ETC',
                menu_bar_value: 'contract_etc'
            },
            isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
        ],
        [{
            label: '인감',
            icon: <SignIcon/>,
            type: 'STAMP',
            menu_bar_value: 'stamp'
        }, isModuleEnabled('CONTRACT_STAMP_MANAGE')],
        [
            {
                label: t('label_contract_sign_seal_process').replace('사인/서류', labelText('sign_and_seal')),
                icon: <SignIcon/>,
                type: 'CONTRACT_SIGN_SEAL_PROCESS',
                menu_bar_value: 'sign_and_seal'
            },
            isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF'),
        ],
        [{label: '분쟁', icon: <DisputeIcon/>, type: 'DISPUTE', menu_bar_value: 'dispute'}, permission.DISPLAY_DISPUTE],
        [{
            label: '수발신문서함',
            icon: <DocIcon/>,
            type: 'DOC_RECEIVED',
            menu_bar_value: 'doc_received'
        }, isModuleEnabled('DOC_RECEIVED')],
        [{
            label: t('MSG_ID_681'),
            icon: <ClaimIcon/>,
            type: 'CLAIM',
            menu_bar_value: 'claim'
        }, isModuleEnabled('MODULE_CLAIM')],
    ];

    type TabOrderProps = { label: string; icon?: JSX.Element; type: string; menu_bar_value: string };
    // 조건에 맞는 탭만 필터링
    const filteredTabs : TabOrderProps[] = allTabs.filter(item => item[1]).map(item => item[0]);

    // 탭을 설정된 순서대로 정렬
    const sortedTabs : TabOrderProps[] = (() => {
        // 설정된 순서대로 탭을 정렬
        const menuOrderSafe = Array.isArray(menuOrderArray) ? menuOrderArray : [];

        const orderedTabs = menuOrderSafe
            .map(orderKey => filteredTabs.find(tabMenu => tabMenu.menu_bar_value === orderKey))
            .filter(Boolean) as TabOrderProps[]; // 설정에 있는 탭만 남김

        // 설정에 없는 탭은 마지막에 추가
        const unorderedTabs = filteredTabs.filter(tabMenu => !menuOrderArray.includes(tabMenu.menu_bar_value));

        return [...orderedTabs, ...unorderedTabs];
    })();

    // "전체" 탭을 무조건 첫 번째에 추가
    if (sortedTabs.length > 1) {
        sortedTabs.unshift({label: '전체', icon: <AllIcon/>, type: 'ALL', menu_bar_value: 'all'});
    }

    const selectedTab = sortedTabs[selectedOrderTab] ? sortedTabs[selectedOrderTab] : sortedTabs[0];

    const {data: workStatusList, isLoading} = useQuery(
        [queryKey.dashboard, queryKey.main, subsidiaryId, selectedOrderTab],
        () => getMainWorkStatus(selectedTab.type as 'ALL' | DashboardWorkStatus['history_type'], subsidiaryId),
        {staleTime: 30000},
    );

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedOrderTab(newValue);
    };


    return (
        <section className='flex gap-5'>
            <CardBox className='basis-1/4' color='bg-neutral-500'>
                <div className='p-8'>
                    <h3 className='text-2xl font-bold text-white text-center'>업무현황</h3>
                </div>
                <Tabs
                    classes={{
                        indicator: 'right-auto left-0 w-2 bg-orange-550',
                    }}
                    orientation='vertical'
                    variant='scrollable'
                    value={selectedOrderTab}
                    onChange={handleChange}
                >
                    {sortedTabs.map((sortedProp, tabIndex) => (
                        <TabButtonDashboard key={sortedProp.label} {...sortedProp} {...a11yProps(tabIndex)} />
                    ))}
                </Tabs>
            </CardBox>

            <article className='basis-3/4 h-screen overflow-y-auto scrollbar'>
                {!isLoading &&
                    Array.from({length: sortedTabs.length}, (_, index) => index).map(index => (
                        <TabPanel key={index} value={selectedOrderTab} index={index} className='h-full'>
                            {workStatusList && workStatusList.length > 0 && <ListDashboard data={workStatusList}/>}
                            {(!workStatusList || workStatusList.length === 0) && (
                                <div className='flex items-center justify-center h-full rounded-2xl bg-white'>
                                    <p className='text-neutral-400'>조회결과가 없습니다.</p>
                                </div>
                            )}
                        </TabPanel>
                    ))}
            </article>
        </section>
    );
}
