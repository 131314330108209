import { InputLabel } from '@mui/material';
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGlobalSearchList } from '../../../context/GlobalSearchContext';
import { GlobalSearchTableRow } from '../../../model/globalSearch';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';
import InputCheckbox from '../../ui/inputs/InputCheckbox';
import InputDate from '../../ui/inputs/InputDate';
import InputRadio from '../../ui/inputs/InputRadio';
import InputText from '../../ui/inputs/InputText';
import PopupBack from './PopupBack';

interface IProps {
	keyword: string;
	formik: FormikValues;
	data: GlobalSearchTableRow;
}

export default function Header({ keyword, formik, data }: IProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { setSearchParams } = useGlobalSearchList();
	const [open, setOpen] = useState(false);

	const handleClick = async () => {
		setOpen(false);
		navigate('/');
	};

	useEffect(() => {
		setSearchParams(prevParams => ({
			...prevParams,
			order: formik.values.sorting,
		}));
	}, [formik.values.sorting, setSearchParams]);

	return (
		<header className='grid grid-cols-6 gap-6'>
			<section className='col-span-1'>
				<PopupBack
					open={open}
					setOpen={setOpen}
					msg={<p>`${t('global_search_reset_keyword')}`</p>}
					onConfirm={handleClick}
				/>
				<h2 className='text-xl font-bold mt-2'>{keyword}</h2>
			</section>
			<section className='col-span-5 grid gap-5'>
				<CardBox className='grid grid-cols-5 gap-8 p-8'>
					<article className='col-span-2 flex items-center gap-3'>
						<InputLabel className='shrink-0'>{t('MSG_ID_796')}</InputLabel>
						<InputDate
							id='startDate'
							label={`${t('cmmn_start')}`}
							size='small'
							format='YYYY.MM.DD'
							formik={formik}
						/>
						<span>-</span>
						<InputDate
							id='endDate'
							label={`${t('cmmn_end')}`}
							size='small'
							format='YYYY.MM.DD'
							formik={formik}
						/>
						<ButtonText
							text={`${t('label_apply')}`}
							variant='outlined'
							onClick={() =>
								setSearchParams(prevParams => ({
									...prevParams,
									startDate: formik.values.startDate,
									endDate: formik.values.endDate,
								}))
							}
						/>
					</article>

					<article className='col-span-3 flex items-center gap-3'>
						<InputText id='reSearchText' size='small' formik={formik} />
						<ButtonText
							className='shrink-0'
							text={`${t('global_search_research')}`}
							variant='outlined'
							onClick={() =>
								setSearchParams(prevParams => ({
									...prevParams,
									reSearchText: formik.values.reSearchText,
								}))
							}
						/>
					</article>
					<article className='col-span-5'>
						<article className='flex items-center gap-2'>
							<InputCheckbox id='isTitle' label={`${t('label_title')}`} formik={formik} />
							<InputCheckbox id='isDescription' label={`${t('label_contents')}`} formik={formik} />
							<InputCheckbox id='isFile' label={`${t('MSG_ID_131')}`} formik={formik} />
							<InputRadio
								id='relevance'
								name='sorting'
								value='relevance'
								label={`${t('global_search_revalance')}`}
								required
								formik={formik}
							/>
							<InputRadio
								id='recent'
								name='sorting'
								value='recent'
								label={`${t('global_search_recent')}`}
								required
								formik={formik}
							/>
						</article>
					</article>
				</CardBox>
				<div className='flex items-center gap-2'>
					<p className='text-xl font-bold'>“{keyword}”</p>
					<p className='text-xl'>
						{t('MSG_ID_972')}
						{data.contractCnt +
							data.counselCnt +
							data.lawsuitCnt +
							data.contractEtcCnt +
							data.noticeCnt +
							data.contractSignSealCnt}
						건
					</p>
				</div>
			</section>
		</header>
	);
}
