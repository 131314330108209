import { FormikValues } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { noticeDetailUrl } from '../../../constants/adeleUrl';
import { useGlobalSearchList } from '../../../context/GlobalSearchContext';
import { GlobalSearchTableRow } from '../../../model/globalSearch';
import SysConfigStore from '../../../store/common/SysConfigStore';
import { openToAdele } from '../../../util/commonUtils';
import ButtonFront from '../../ui/buttons/ButtonFront';
import ButtonText from '../../ui/buttons/ButtonText';
import FileChip from '../../ui/chips/FileChip';
import Highlight from '../../ui/highlights/Highlight';
import BoardIcon from '../../ui/icons/apps/custom/sk/BoardIcon';
import TableFooter from '../../ui/tables/TableFooter';
import ItemSearchField from '../ItemSearchField';
import PopupPreviewFile from '../PopupPreviewFile';

interface IProps {
	data: GlobalSearchTableRow;
	formik: FormikValues;
}

export default function NoticePanel({ data, formik }: IProps) {
	const { t } = useTranslation();
	const { isComponentEnabled } = SysConfigStore();
	const { searchParams, setSearchParams } = useGlobalSearchList();
	const handleChangePage = (e: unknown, newPage: number) => {
		setSearchParams(prev => ({ ...prev, currentPage: newPage }));
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchParams(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value, 10), currentPage: 0 }));
	};

	const detailUri = isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF')
		? '/manage/notice-board-detail-view/'
		: noticeDetailUrl;

	const handleNavigate = (noticeId: number) => {
		sessionStorage.setItem(
			detailUri,
			JSON.stringify({
				notice_id: noticeId,
			}),
		);

		openToAdele(detailUri);
	};
	return (
		<>
			<p className='font-bold'>
				{t('label_notice_board')} {data.noticeCnt}
			</p>
			<ul className='col-span-9 grid gap-12 [&>li]:pb-12 [&>li:last-of-type]:pb-0 [&>li]:border-b [&>li:last-child]:border-none'>
				{data &&
					data.noticeList.map(row => (
						<li key={row.id} className='grid gap-6'>
							{formik.values.isTitle && (
								<section>
									<div className='flex items-center gap-2'>
										<BoardIcon className='text-neutral-500' />
										<ButtonText
											className='text-lg font-bold'
											onClick={() => handleNavigate(row.id)}
										>
											<Highlight text={row.noti_title} pattern='' />
										</ButtonText>
									</div>
								</section>
							)}
							<section className='grid grid-cols-4 gap-6'>
								{formik.values.isDescription && (
									<>
										<ItemSearchField title={t('label_notice_title')}>
											<p>
												<Highlight text={row.title} pattern='' />
											</p>
										</ItemSearchField>
										<ItemSearchField title={t('MSG_ID_348')}>
											<p>
												<Highlight text={row.user_name} pattern='' />
											</p>
										</ItemSearchField>
										<ItemSearchField
											className='col-span-4 items-start'
											title={t('cmmn_content')}
											value={row.noti_content}
										>
											<div className='flex-1'>
												<Highlight text={row.noti_content} pattern='' />
											</div>
										</ItemSearchField>
									</>
								)}

								{formik.values.isFile &&
									data.attachList
										.filter(item => item.doc_type === 'bnotice_attachment')
										.filter(item => item.notice_id === row.id).length > 0 && (
										<ItemSearchField className='col-span-4 items-center' title={t('MSG_ID_131')}>
											<ul className='flex-1 flex flex-wrap items-center gap-2'>
												{data.attachList
													.filter(item => item.doc_type === 'bnotice_attachment')
													.filter(item => item.notice_id === row.id)
													.map(item => (
														<li key={item.pk} className='flex gap-2'>
															<FileChip
																text={<Highlight text={item.file_name} pattern='' />}
																disabled
															/>
															<PopupPreviewFile
																data={
																	data.attachPreviewList.find(
																		attach => attach.pk === item.pk,
																	) ?? { pk: '', file_contents_summary: '' }
																}
																allText={item}
															/>
														</li>
													))}
											</ul>
										</ItemSearchField>
									)}
							</section>
						</li>
					))}
			</ul>
			{searchParams.searchCtg === 'ALL' ? (
				data.noticeCnt !== 0 && (
					<div className='col-span-10 flex justify-end pt-12'>
						<ButtonFront
							text={t('global_search_see_all')}
							onClick={() =>
								setSearchParams(prev => ({
									...prev,
									searchCtg: 'BOARD',
								}))
							}
						/>
					</div>
				)
			) : (
				<TableFooter
					className='col-span-10 flex justify-end pt-12'
					rowsPerPageOptions={[10, 20, 30, 50]}
					showFirstButton
					showLastButton
					count={data.noticeCnt}
					rowsPerPage={searchParams.rowsPerPage}
					page={searchParams.currentPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</>
	);
}
