import { Checkbox, FormHelperText, InputLabel, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { FormikValues } from 'formik';
import { ReactNode, useCallback } from 'react';
import { useAlert } from '../../../hooks/useAlert';
import { Tag } from '../../../model/tag';
import ItemChip from '../chips/ItemChip';
import LabelChip from '../chips/LabelChip';
/**
 * option 정의
 * icon: option 에 icon 표시
 * name: ui 에 보여지는 이름
 * value: 실제 저장되는 값
 */
export type DefaultOption = {
	id?: string | number;
	icon?: ReactNode;
	name?: string | ReactNode;
	value: string | number;
};

type Option = DefaultOption | Tag;
/**
 * OptionType 정의
 * text: 문자열만 표시
 * checkbox: checkbox ui 표시
 * tag: 라벨 표시
 */
type OptionType = 'text' | 'checkbox' | 'tag' | 'chip';

type SelectPropsOverrides = SelectProps & {
	id: string;
	size?: 'small' | 'medium';
	type?: OptionType;
	defaultValue?: string | string[] | undefined;
	formik: FormikValues;
	options: Option[] | Tag[];
	allowEmptyCheck?: boolean;
	required?: boolean;
};

export default function InputSelect({
	id,
	label,
	type = 'text',
	multiple = false,
	size = 'medium',
	options,
	classes = { root: undefined },
	disabled = false,
	placeholder,
	required = false,
	formik,
	allowEmptyCheck = true,
	...props
}: SelectPropsOverrides) {
	const { root = 'rounded' } = classes;
	const [snackbar] = useAlert();

	// 선택된 option 형태
	const handleRenderValue = useCallback(
		(selected: string | string[]): ReactNode => {
			let result: ReactNode;
			switch (type) {
				case 'text':
				case 'checkbox':
				case 'chip': {
					result = (
						<div className='flex flex-wrap gap-3'>
							{Array.isArray(selected)
								? selected.map(
										(value: string) =>
											value && (
												<ItemChip
													key={value}
													text={
														options.filter(item => item.value === value)[0]?.name as string
													}
													disabled
												/>
											),
									)
								: selected && (
										<ItemChip
											text={options.filter(item => item.value === selected)[0]?.name as string}
											disabled
										/>
									)}
						</div>
					);

					break;
				}
				case 'tag': {
					// label 형태 option
					result = (
						<div className='flex flex-wrap gap-3'>
							{Array.isArray(selected)
								? selected.map(
										(value: string) =>
											value && (
												<LabelChip
													key={value}
													id={id}
													tag={options.filter(item => item.value === value)[0] as Tag}
													formik={formik}
													disabled={disabled}
												/>
											),
									)
								: selected && (
										<LabelChip
											key={selected}
											id={id}
											tag={options.filter(item => item.value === selected)[0] as Tag}
											formik={formik}
											disabled={disabled}
										/>
									)}
						</div>
					);
					break;
				}
				default:
			}

			return result;
		},
		[id, options],
	);

	// option list 형태
	const handleOptionList = useCallback(
		(option: Option) => {
			let item: ReactNode;

			switch (type) {
				case 'text':
				case 'chip': {
					// 텍스트 형태 option
					const { icon, name = '', value } = option as DefaultOption;
					item = (
						<MenuItem key={value} value={value}>
							<div className='flex items-center gap-2'>
								{icon && icon}
								{name && name}
							</div>
						</MenuItem>
					);
					break;
				}
				case 'checkbox': {
					// checkbox 형태 option
					const { name = '', value } = option as DefaultOption;
					const checked = type === 'checkbox' && formik.values[id].includes(value);
					item = (
						<MenuItem key={value} value={value}>
							<Checkbox
								checked={checked}
								disabled={!allowEmptyCheck && checked && formik.values[id].length === 1}
							/>
							<ListItemText primary={name} />
						</MenuItem>
					);
					break;
				}
				case 'tag': {
					// label 형태 option
					const { value } = option as Tag;
					item = (
						<MenuItem key={value} value={value}>
							<LabelChip id={value} tag={option as Tag} disabled />
						</MenuItem>
					);
					break;
				}
				// case 'chip': {
				// 	// label 형태 option
				// 	const { value } = option as Option;
				// 	item = (
				// 		<MenuItem key={value} value={value}>
				// 			<ItemChip key={value} text={option.name as string} disabled />
				// 		</MenuItem>
				// 	);
				// 	break;
				// }
				default:
			}
			return item;
		},
		[id, options],
	);

	const handleChange = useCallback(
		(selectedOption: SelectChangeEvent<string | string[]>) => {
			if (type === 'checkbox' && !allowEmptyCheck && !selectedOption?.target?.value.length) {
				snackbar('한 개 이상의 항목을 필수로 선택해야 합니다.', 'warning');
			} else {
				formik.setFieldValue(id, selectedOption?.target?.value);
			}
		},
		[id, options],
	);

	return (
		<div>
			<FormControl
				classes={{
					root: `relative w-full ${root}`,
				}}
				size={size}
				error={formik.touched[id] && formik.errors[id] && true}
			>
				<InputLabel id={`${id}_label`} required={required}>
					{label}
				</InputLabel>
				<Select
					id={id}
					label={label}
					labelId={`${id}_label`}
					multiple={multiple}
					value={formik.values[id]}
					renderValue={handleRenderValue}
					disabled={disabled}
					onChange={selectedOption => handleChange(selectedOption)}
					{...props}
				>
					{placeholder && <MenuItem disabled>{placeholder}</MenuItem>}
					{!required && !multiple && <MenuItem value=''>선택 없음</MenuItem>}
					{options.map(option => handleOptionList(option))}
				</Select>
				{formik.touched[id] && formik.errors[id] && (
					<FormHelperText classes={{ root: 'absolute bottom-0 translate-y-full mx-0' }} error>
						{formik.errors[id]}
					</FormHelperText>
				)}
			</FormControl>
		</div>
	);
}
