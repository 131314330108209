import { useQueryClient } from '@tanstack/react-query';
import { queryKey } from '../constants/queryKey';
import { useGlobalSearchList } from '../context/GlobalSearchContext';

export default function useGlobalSearch() {
	const queryClient = useQueryClient();

	const { searchParams } = useGlobalSearchList();
	const listKeys = [queryKey.globalSearch, queryKey.list, searchParams];
	const resetGlobalSearch = () => queryClient.invalidateQueries(listKeys);

	return { resetGlobalSearch, listKeys };
}
