import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachSearchTableRow } from '../../model/globalSearch';
import TinyMCE from '../common/editor/TinyMCE';
import ButtonText from '../ui/buttons/ButtonText';
import PopupCommonExternal from '../ui/popups/PopupCommonExternal';

interface IProps {
	data: AttachSearchTableRow;
	lineText: string;
}

export default function PopupPreviewFileDetail({ data, lineText }: IProps) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [text, setText] = useState('');
	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);

	function removePagePattern(inputString: string) {
		// 정규식을 사용하여 ..PAGE:숫자 패턴을 찾아서 제거합니다.
		return inputString.replace(/\.\.PAGE:\d+/g, '');
	}

	function extractSurroundingText(a: string, b: string): string | null {
		// a와 b의 HTML 태그를 제거
		a = a.replace(/<\/?[^>]+(>|$)/g, '');
		b = b.replace(/<\/?[^>]+(>|$)/g, '');

		// 문자열 b에서 a의 위치를 찾음
		const index = b.indexOf(a);

		// a가 b에 포함되어 있지 않으면 null 반환
		if (index === -1) {
			return null;
		}

		// 앞뒤로 100글자씩 자르기 위해 시작과 끝 인덱스 계산
		const start = Math.max(0, index - 100);
		const end = Math.min(b.length, index + a.length + 100);

		// 잘라낸 부분
		let surroundingText = b.substring(start, end);

		// a 앞뒤로 <mark> 태그 추가
		const markedA = `<mark>${a}</mark>`;

		// surroundingText에서 a를 <mark>태그로 감싼 것으로 대체
		surroundingText = surroundingText.replace(a, markedA);

		return surroundingText;
	}

	useEffect(() => {
		const tmpText = extractSurroundingText(lineText, removePagePattern(data.file_contents));
		if (tmpText) setText(tmpText);
	}, [data, lineText]);

	const render = useMemo(() => {
		return (
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold text-left'>{t('MSG_ID_68')}</h2>
				</div>
				<div className='p-3 border border-neutral-500 rounded-2xl bg-zinc-100'>
					<TinyMCE
						initialValue={text && text}
						init={{ height: 200, menubar: false, toolbar: false, statusbar: false, readonly: true }}
						disabled
					/>
				</div>
			</section>
		);
	}, [data, lineText, text]);
	return (
		<>
			<ButtonText text={t('preview_content')} size='small' variant='outlined' onClick={handleOpen} />
			<PopupCommonExternal open={open} msg={render} onClose={handleClose} onCancel={handleClose} />
		</>
	);
}
