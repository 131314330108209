import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useCompanyInfo from '../hooks/useCompanyInfo';
import { Subsidiary } from '../model/api/company';
import SysConfigStore from '../store/common/SysConfigStore';
import { clearSearchParam } from '../util/commonUtils';
import { useUser } from './UserContext';

type SubsidiaryContext = {
	isActive: boolean;
	subsidiaries: Subsidiary[];
	id: number;
	setId: React.Dispatch<React.SetStateAction<number>>;
};

const SubsidiaryContext = createContext<SubsidiaryContext | undefined>(undefined);

type Props = {
	children: React.ReactNode;
	isActive: boolean;
	subsidiaries: SubsidiaryContext['subsidiaries'];
};

export function SubsidiaryProvider({ children, isActive, subsidiaries }: Props) {
	const { isComponentEnabled } = SysConfigStore();
	const { refetchCompanyInfo } = useCompanyInfo();

	const { loginUser } = useUser();
	
	const defaultSelectedId = isComponentEnabled("CMPNT148_SUBSIDIARY_DEFAULT_SELECT_01_BF") && loginUser.subsidiaryId ? loginUser.subsidiaryId : -1;
	const selectedId = Number(sessionStorage.getItem('subsidiary_search_id')) || defaultSelectedId

	const [id, setId] = useState<number>(selectedId);

	const value = useMemo(() => ({ id, setId, isActive, subsidiaries }), [id, isActive, subsidiaries]);

	useEffect(() => {
		if (isComponentEnabled('CMPNT145_SUBSIDIARY_MODULE_CUSTOM_01_BF')) {
			// SearchParam을 지워야 검색조건 유지가 풀려서 정상적으로 동작한다.
			sessionStorage.setItem('subsidiary_search_id', id.toString());
			clearSearchParam();
			refetchCompanyInfo();
		}
	}, [id]);

	return <SubsidiaryContext.Provider value={value}>{children}</SubsidiaryContext.Provider>;
}

export function useSubsidiary() {
	const context = useContext(SubsidiaryContext);
	if (context === undefined) {
		throw new Error('useSubsidiary must be used within a SubsidiaryProvider');
	}

	return context;
}
