import { useLocation, useNavigate } from 'react-router-dom';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import SearchIcon from '../../../ui/icons/SearchIcon';
import InputText from '../../../ui/inputs/InputText';

export default function MenuSearch() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const formik = useValidateForm({
		validationSchema: {
			keyword: { initialValue: (new URLSearchParams(search).get('keyword') || '').trim(), type: 'string' },
		},
		onSubmit: () => {
			formik.setFieldValue('keyword', formik.values.keyword.trim());
			navigate(`/globalSearch`, { state: { keyword: formik.values.keyword.trim() } });
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className='w-full'>
			<InputText
				id='keyword'
				classes={{ root: 'border-transparent rounded-3xl bg-zinc-100' }}
				icon={<SearchIcon classes={{ root: 'cursor-pointer' }} onClick={formik.handleSubmit} />}
				labelText='통합 검색'
				placeholder='검색 내용을 입력해주세요.'
				size='small'
				formik={formik}
			/>
		</form>
	);
}
