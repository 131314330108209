import { useTranslation } from 'react-i18next';
import { contractCreateUrl, counselCreateUrl } from '../../../../../constants/adeleUrl';
import { useUser } from '../../../../../context/UserContext';
import SysConfigStore from '../../../../../store/common/SysConfigStore';
import { navigateToAdele } from '../../../../../util/commonUtils';
import ButtonText from '../../../../ui/buttons/ButtonText';
import ContractIcon from '../../../../ui/icons/apps/custom/sk/ContractIcon';
import CounselIcon from '../../../../ui/icons/apps/custom/sk/CounselIcon';
import ReviewIcon from '../../../../ui/icons/apps/custom/sk/ReviewIcon';
import SignIcon from '../../../../ui/icons/apps/custom/sk/SignIcon';
import PopoverInternalState from '../../../../ui/popovers/PopoverInternalState';
import AlarmButtonDashboard from './AlarmButtonDashboard';
import AlarmDisplayDashboard from './AlarmDisplayDashboard';
import NoticeListDashboard from './NoticeListDashboard';
import StatusDashboardDefault from './StatusDashboardDefault';
import StatusDashboardLawyer from './StatusDashboardLawyer';
import StatusDashboardLawyerCustom from './StatusDashboardLawyerCustom';
import StatusDigitalCertificationDashboardDefault from './StatusDigitalCertificationDashboardDefault';
import StatusSignSealProcessDashboardDefault from './StatusSignSealProcessDashboardDefault';

export default function HeaderDashboard() {
	const { isLawyer, permission, isLawyerManageGroup } = useUser();
	const { isComponentEnabled, labelText } = SysConfigStore();
	const handleNavigate = (type: 'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC' | 'CONTRACT_SIGN_SEAL_PROCESS') => {
		const urlMap = {
			CONTRACT: contractCreateUrl,
			COUNSEL: counselCreateUrl,
			CONTRACT_ETC: contractCreateUrl,
			CONTRACT_SIGN_SEAL_PROCESS: contractCreateUrl,
		};

		if (['CONTRACT', 'CONTRACT_ETC', 'CONTRACT_SIGN_SEAL_PROCESS'].includes(type)) {
			sessionStorage.setItem('/contract/get_list_view_template/', JSON.stringify({ template_type: type }));
		}

		if (['COUNSEL'].includes(type) && isComponentEnabled('CMPNT169_COUNSEL_CUSTOM_BF')) {
			urlMap.COUNSEL = '/counsel/get-list-view-v28/';
			sessionStorage.setItem('CMPNT169_COUNSEL_CUSTOM_BF_isAutoOpen', 'true');
		}

		navigateToAdele(urlMap[type]);
	};
	const { t } = useTranslation();
	const renderContent = () => {
		if (isComponentEnabled('CMPNT325_SKBP_CUSTOM_B')) {
			if (isLawyerManageGroup) {
				return <StatusDashboardLawyer />;
			}
			if (isLawyer) {
				return <StatusDashboardLawyerCustom />;
			}
			return <StatusDashboardDefault />;
		}
		if (isLawyer) {
			return <StatusDashboardLawyer />;
		}
		return <StatusDashboardDefault />;
	};

	const getSignSealDashboard = () => {
		if (isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF')) {
			if (isComponentEnabled('CMPNT342_HIDE_MENU_SIGNSEAL_B')) {
				return null
			}
			return <StatusSignSealProcessDashboardDefault />
		}
		return null
	};

	const getSignsealButton = () => {
		if (isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF')) {
			if (isComponentEnabled('CMPNT342_HIDE_MENU_SIGNSEAL_B')) {
				return null
			}
			return <ButtonText
						classes={{ root: 'justify-start gap-6 pl-10 h-full rounded-lg bg-white' }}
						color='secondary'
						variant='outlined'
						onClick={() => handleNavigate('CONTRACT_SIGN_SEAL_PROCESS')}
					>
						<SignIcon className='text-neutral-600' fontSize='large' />
						<p className='text-xl text-gray-950 font-bold'>
							{t('new_contract_sign_seal_process').replace('사인/서류', labelText('sign_and_seal'))}
						</p>
					</ButtonText>
		}
		return null
	};

	return (
		<header className='grid grid-cols-1 gap-5 w-full lg:grid-cols-5'>
			<div className='grid grid-cols-1 gap-5 lg:grid-cols-4 lg:col-span-3'>
				<PopoverInternalState openTrigger={<AlarmButtonDashboard />}>
					<AlarmDisplayDashboard />
				</PopoverInternalState>

				{renderContent()}

				{getSignSealDashboard()}

				{isComponentEnabled('CMPNT163_DIGITAL_CERTIFICATE_BF') ? (
					<StatusDigitalCertificationDashboardDefault />
				) : null}
			</div>
			<div className='lg:col-span-1'>
				<NoticeListDashboard />
			</div>
			<div className='flex flex-col gap-5 lg:col-span-1'>
				<ButtonText
					classes={{ root: 'justify-start gap-6 pl-10 h-full rounded-lg bg-white' }}
					color='secondary'
					variant='outlined'
					onClick={() => handleNavigate('CONTRACT')}
				>
					<ContractIcon className='text-neutral-600' fontSize='large' />
					<p className='text-xl text-gray-950 font-bold'>새 계약</p>
				</ButtonText>
				{permission.DISPLAY_COUNSEL && (
					<ButtonText
						classes={{ root: 'justify-start gap-6 pl-10 h-full rounded-lg bg-white' }}
						color='secondary'
						variant='outlined'
						onClick={() => handleNavigate('COUNSEL')}
					>
						<CounselIcon className='text-neutral-600' fontSize='large' />
						<p className='text-xl text-gray-950 font-bold'>{t('cmmn_label_new_counsel_create')}</p>
					</ButtonText>
				)}
				{isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') ? (
					<ButtonText
						classes={{ root: 'justify-start gap-6 pl-10 h-full rounded-lg bg-white' }}
						color='secondary'
						variant='outlined'
						onClick={() => handleNavigate('CONTRACT_ETC')}
					>
						<ReviewIcon className='text-neutral-600' fontSize='large' />
						<p className='text-xl text-gray-950 font-bold'>
							{t('new_contract_etc').replace('{{계약외문서}}', labelText('etc_contract'))}
						</p>
					</ButtonText>
				) : null}
				{getSignsealButton()}
			</div>
		</header>
	);
}
