import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import {
	getDashboardRealtimeStatistics,
	getDashboardRealtimeStatistics3,
	getDashboardRealtimeStatistics4,
	getDashboardRealtimeStatistics5,
	getDashboardReviewTimeStatistics,
} from '../../../api/statistics';
import { STATISTICS_CHART_COLOR } from '../../../constants/common';
import { useSubsidiary } from '../../../context/SubsidiaryContext';
import {
	RealtimeLawsuitManager,
	RealtimeReviewManage,
	RealtimeReviews,
	ReviewTimes,
	ThreeWeeksNewReview,
} from '../../../model/statistics';
import sysConfigStore from '../../../store/common/SysConfigStore';
import { getAreaLineOption, getBarVerticalOption, getPieOption } from '../../../util/chartOptions';
import Chart from '../../ui/Chart';
import AccordionDefault from '../../ui/accordions/AccordionDefault';
import CardBox from '../../ui/containers/CardBox';
import ExpandMoreIcon from '../../ui/icons/ExpandMoreIcon';

export default function AccodionChartDashboard() {
	const { isModuleEnabled, isComponentEnabled, labelText } = sysConfigStore();
	const [resRealtimeStatisticsData, setResRealtimeStatisticsData] = useState<RealtimeReviews>();
	const [resReviewTimesStatisticsData, setResReviewTimesStatisticsData] = useState<ReviewTimes>();
	const [resThreeWeeksNewReviewStatisticsData, setResThreeWeeksNewReviewStatisticsData] =
		useState<ThreeWeeksNewReview>();
	const [resRealtimeReviewManageData, setResRealtimeReviewManageData] = useState<RealtimeReviewManage>();
	const [resRealtimeLawsuitManagerData, setResRealtimeLawsuitManagerData] = useState<RealtimeLawsuitManager>();
	const { t } = useTranslation();

	const toggleDashboard = () => {
		const valToggle = localStorage.getItem('toggle_dashboard');
		if (valToggle !== undefined) {
			if (valToggle === 'false') {
				return false;
			}
			return true;
		}
		return true;
	};

	const { id: subsidiaryId } = useSubsidiary();

	const [toggleReportViewer, setToggleReportViewer] = useState<boolean>(() => toggleDashboard());

	const updateToggleReportViewer = (value: boolean) => {
		localStorage.setItem('toggle_dashboard', String(value));
		setToggleReportViewer(value);
	};

	const getRealtimeStatistics = () => {
		getDashboardRealtimeStatistics({
			subsidiary: subsidiaryId,
			period: 'week',
			startDate: '',
			endDate: '',
		}).then(res => {
			setResRealtimeStatisticsData(res);
		});

		getDashboardReviewTimeStatistics({
			subsidiary: subsidiaryId,
			period: 'realtime',
			startDate: '',
			endDate: '',
		}).then(res => {
			setResReviewTimesStatisticsData(res);
		});

		getDashboardRealtimeStatistics3({
			subsidiary: subsidiaryId,
			period: 'week',
		}).then(res => {
			setResThreeWeeksNewReviewStatisticsData(res);
		});

		getDashboardRealtimeStatistics4({
			subsidiary: subsidiaryId,
			period: 'week',
		}).then(res => {
			setResRealtimeReviewManageData(res);
		});

		getDashboardRealtimeStatistics5({
			subsidiary: subsidiaryId,
			period: 'week',
		}).then(res => {
			setResRealtimeLawsuitManagerData(res);
		});
	};

	const data: { value: number; name: string; color: string }[] = [];

	const data2: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const data3: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const data4: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const getRealtimeReviewChart = () => {
		const conCnt = resRealtimeStatisticsData?.contract_count ?? 0;
		const counselCnt = resRealtimeStatisticsData?.counsel_count ?? 0;
		const conEtcCnt = resRealtimeStatisticsData?.contract_etc_contract_count ?? 0;

		data.push({
			value: conCnt,
			name: '계약',
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		data.push({
			value: counselCnt,
			name: '자문',
			color: STATISTICS_CHART_COLOR.COUNSEL,
		});
		if (isComponentEnabled('CMPNT165_CONTRACT_ETC_BF')) {
			data.push({
				value: conEtcCnt,
				name: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
				color: STATISTICS_CHART_COLOR.CONTRACT_ETC,
			});
		}

		return getPieOption({ data, name: '신규 검토 법무별 건수' });
	};

	const calculateContractReviewTime = () => {
		const avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) === 0) return 0.0;

		return (avgReviewTime / 24).toFixed(1);
	};

	const calculateContractEtcReviewTime = () => {
		const avgReviewTime =
			(resReviewTimesStatisticsData?.contract_etc_total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.contract_etc_reviewed_contract_ids?.length ?? 0);

		if ((resReviewTimesStatisticsData?.contract_etc_reviewed_contract_ids?.length ?? 0) === 0) return 0.0;

		return (avgReviewTime / 24).toFixed(1);
	};

	const calculateCounselReviewTime = () => {
		const avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_counsel_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0) === 0) return 0.0;

		return (avgReviewTime / 24).toFixed(1);
	};

	const calculateContractReviewTimeVariance = () => {
		let avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) === 0) {
			avgReviewTime = 0;
		}

		const comparisonReviewTime =
			(resReviewTimesStatisticsData?.comparison_total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.comparison_reviewed_contract_ids.length ?? 0);

		if (
			comparisonReviewTime === 0 ||
			(resReviewTimesStatisticsData?.comparison_reviewed_contract_ids.length ?? 0) === 0
		) {
			return '-';
		}

		// 여기 다시 계산 필요
		const rate = ((avgReviewTime - comparisonReviewTime) / 24 / (comparisonReviewTime / 24)) * 100;

		return rate > 0 ? `+${rate.toFixed(1) as string}` : rate.toFixed(1);
	};

	const calculateContractEtcReviewTimeVariance = () => {
		let avgReviewTime =
			(resReviewTimesStatisticsData?.contract_etc_total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.contract_etc_reviewed_contract_ids?.length ?? 0);

		if ((resReviewTimesStatisticsData?.contract_etc_reviewed_contract_ids?.length ?? 0) === 0) {
			avgReviewTime = 0;
		}

		const comparisonReviewTime =
			(resReviewTimesStatisticsData?.contract_etc_comparison_total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.contract_etc_comparison_reviewed_contract_ids?.length ?? 0);

		if (
			comparisonReviewTime === 0 ||
			(resReviewTimesStatisticsData?.contract_etc_comparison_reviewed_contract_ids?.length ?? 0) === 0
		) {
			return '-';
		}

		// 여기 다시 계산 필요
		const rate = ((avgReviewTime - comparisonReviewTime) / 24 / (comparisonReviewTime / 24)) * 100;

		return rate > 0 ? `+${rate.toFixed(1) as string}` : rate.toFixed(1);
	};

	const calculateCounselReviewTimeVariance = () => {
		let avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_counsel_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0) === 0) {
			avgReviewTime = 0;
		}

		const comparisonReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_comparison_counsel_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_comparison_counsel_ids.length ?? 0);

		// console.log(comparisonReviewTime);

		if (
			comparisonReviewTime === 0 ||
			(resReviewTimesStatisticsData?.reviewed_comparison_counsel_ids.length ?? 0) === 0
		) {
			return '-';
		}

		// 여기 다시 계산 필요
		const rate = ((avgReviewTime - comparisonReviewTime) / 24 / (comparisonReviewTime / 24)) * 100;
		// console.log(rate);

		return rate > 0 ? `+${rate.toFixed(1) as string}` : rate.toFixed(1);
	};

	const threeWeekNewReviewCountLineChart = () => {
		const conDate = resThreeWeeksNewReviewStatisticsData?.three_weeks_contract ?? {};
		const counselDate = resThreeWeeksNewReviewStatisticsData?.three_weeks_counsel ?? {};
		const conEtcDate = resThreeWeeksNewReviewStatisticsData?.three_weeks_contract_etc ?? {};

		const allDates = _.union(_.keys(conDate), _.keys(counselDate), _.keys(conEtcDate));

		_.forEach(allDates, key => {
			if (conDate[key] === undefined) {
				conDate[key] = 0;
			}
			if (counselDate[key] === undefined) {
				counselDate[key] = 0;
			}
			if (conEtcDate[key] === undefined) {
				conEtcDate[key] = 0;
			}
		});

		data2.xAxisData = _.keys(conDate).sort();

		data2.data.push({
			name: '계약',
			data: data2.xAxisData.map(item => conDate[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});

		data2.data.push({
			name: '자문',
			data: data2.xAxisData.map(item => counselDate[item]),
			color: STATISTICS_CHART_COLOR.COUNSEL,
		});

		if (isComponentEnabled('CMPNT165_CONTRACT_ETC_BF')) {
			data2.data.push({
				name: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
				data: data2.xAxisData.map(item => conEtcDate[item]),
				color: STATISTICS_CHART_COLOR.CONTRACT_ETC,
			});
		}

		return getAreaLineOption(data2);
	};

	const realtimeReviewManagerChartData = () => {
		const conManager = resRealtimeReviewManageData?.contract_reviewers ?? {};
		const counselManager = resRealtimeReviewManageData?.counsel_reviewers ?? {};

		const allManagers = _.union(_.keys(conManager), _.keys(counselManager));

		_.forEach(allManagers, key => {
			if (conManager[key] === undefined) {
				conManager[key] = 0;
			}
			if (counselManager[key] === undefined) {
				counselManager[key] = 0;
			}
		});

		data3.xAxisData = _.keys(conManager).sort();

		data3.data.push({
			name: '계약',
			data: data3.xAxisData.map(item => conManager[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});

		data3.data.push({
			name: '자문',
			data: data3.xAxisData.map(item => counselManager[item]),
			color: STATISTICS_CHART_COLOR.COUNSEL,
		});

		if (isComponentEnabled('CMPNT165_CONTRACT_ETC_BF')) {
			const etcConManager = resRealtimeReviewManageData?.contract_etc_reviewers ?? {};
			data3.data.push({
				name: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
				data: data3.xAxisData.map(item => etcConManager[item]),
				color: STATISTICS_CHART_COLOR.CONTRACT_ETC,
			});
		}

		return getBarVerticalOption(data3);
	};

	const realtimeLawsuitManagerChartData = () => {
		const managers: string[] = _.keys(resRealtimeLawsuitManagerData?.lawsuit_managers)
			.map(item => item.split('|')[0])
			.sort();
		let innerCnt: number[] = [];
		let outerCnt: number[] = [];

		data4.xAxisData = managers.filter((v, i) => managers.indexOf(v) === i);

		innerCnt = managers.map(item => {
			return resRealtimeLawsuitManagerData !== undefined &&
				resRealtimeLawsuitManagerData.lawsuit_managers[`${item}|INNER_LAWYER`] !== undefined
				? resRealtimeLawsuitManagerData.lawsuit_managers[`${item}|INNER_LAWYER`]
				: 0;
		});

		outerCnt = managers.map(item => {
			return resRealtimeLawsuitManagerData !== undefined &&
				resRealtimeLawsuitManagerData.lawsuit_managers[`${item}|OUTER_LAWYER`] !== undefined
				? resRealtimeLawsuitManagerData.lawsuit_managers[`${item}|OUTER_LAWYER`]
				: 0;
		});

		data4.data.push({
			name: '내부',
			data: innerCnt,
			color: STATISTICS_CHART_COLOR.LAWSUIT_INNER_LAWYER,
		});

		data4.data.push({
			name: '외부',
			data: outerCnt,
			color: STATISTICS_CHART_COLOR.LAWSUIT_OUTER_LAWYER,
		});

		return getBarVerticalOption(data4);
	};

	const reviewedCountLineChart = (resData?: ReviewTimes) => {
		const twoMonthsAgo = dayjs().subtract(2, 'month');

		const conDate = resData?.reviewed_contract_datetime
			.map(item => item.split('|')[0])
			.filter(item => dayjs(item).isAfter(twoMonthsAgo))
			.sort();

		const counselDate = resData?.reviewed_counsel_datetime
			.map(item => item.split('|')[0])
			.filter(item => dayjs(item).isAfter(twoMonthsAgo))
			.sort();

		const conDateCnt = conDate?.map(item => conDate?.filter(el => item === el).length);

		const counselDateObj = counselDate?.map(item => counselDate?.filter(el => item === el).length);

		const concatContractObj: AnyObject = _.zipObjectDeep(conDate, conDateCnt);
		const concatCounselObj: AnyObject = _.zipObjectDeep(counselDate, counselDateObj);

		const allDates = _.union(_.keys(concatContractObj), _.keys(concatCounselObj));

		_.forEach(allDates, key => {
			if (concatContractObj[key] === undefined) {
				concatContractObj[key] = 0;
			}
			if (concatCounselObj[key] === undefined) {
				concatCounselObj[key] = 0;
			}
		});

		const reviewedCountData: {
			xAxisData: string[];
			data: { name: string; data: number[]; color: string }[];
		} = { xAxisData: [], data: [] };

		reviewedCountData.xAxisData = _.keys(concatContractObj).sort();

		reviewedCountData.data.push({
			name: '계약',
			data: reviewedCountData.xAxisData.map(item => concatContractObj[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			reviewedCountData.data.push({
				name: '자문',
				data: reviewedCountData.xAxisData.map(item => concatCounselObj[item]),
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		return getAreaLineOption(reviewedCountData);
	};

	useEffect(() => {
		getRealtimeStatistics();
	}, []);

	useEffect(() => {
		getRealtimeStatistics();
	}, [subsidiaryId]);

	return (
		<AccordionDefault
			expanded={toggleReportViewer}
			classes={{ root: 'flex flex-col' }}
			summaryClasses={{ root: 'self-end' }}
			expandIcon={<ExpandMoreIcon color='primary' />}
			summaryOpen={<p className='text-m font-bold text-blue-600'>실시간 리포트 접기</p>}
			summaryClose={<p className='text-m font-bold text-blue-600'>실시간 리포트 펴기</p>}
			changeCallback={updateToggleReportViewer}
		>
			<div className='grid grid-cols-1 xl:grid-cols-2 gap-5 mt-2'>
				<div className='grid grid-cols-1 gap-5 xl:grid-cols-5'>
					<div className='col-span-1 xl:col-span-2'>
						<CardBox className='flex flex-col gap-6 h-full py-6 px-10'>
							<p className='flex items-center gap-2 text-lg font-bold text-neutral-500'>
								최근 3개월
								<br />
								평균 검토 처리 기간
							</p>
							<div className='flex items-center gap-2 justify-between'>
								<div className='flex flex-col items-center gap-4'>
									<div className='flex flex-col items-center'>
										<p className='flex text-center gap-1 text-5xl'>
											{calculateContractReviewTime()}
										</p>
										<span className='text-xl text-center font-bold'>day</span>
									</div>
									<div className='flex flex-col items-center'>
										<p className='flex text-center gap-1 text-5xl'>
											{calculateCounselReviewTime()}
										</p>
										<span className='text-xl text-center font-bold'>day</span>
									</div>
									{isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') && (
										<div className='flex flex-col items-center'>
											<p className='flex text-center gap-1 text-5xl'>
												{calculateContractEtcReviewTime()}
											</p>
											<span className='text-xl text-center font-bold'>day</span>
										</div>
									)}
								</div>
								<div className='flex flex-col items-center gap-8'>
									<div className='flex flex-col'>
										<span className='text-sm text-right text-neutral-500'>계약</span>
										<span className='text-m text-neutral-500'>
											{calculateContractReviewTimeVariance()}%
										</span>
									</div>
									<div className='flex flex-col'>
										<span className='text-sm text-right text-neutral-500'>자문</span>
										<span className='text-m text-neutral-500'>
											{calculateCounselReviewTimeVariance()}%
										</span>
									</div>
									{isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') && (
										<div className='flex flex-col'>
											<span className='text-sm text-right text-neutral-500'>
												{t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract'))}
											</span>
											<span className='text-m text-neutral-500'>
												{calculateContractEtcReviewTimeVariance()}%
											</span>
										</div>
									)}
								</div>
							</div>
						</CardBox>
					</div>
					<div className='col-span-1 xl:col-span-3'>
						<CardBox className='flex flex-col gap-4 p-6'>
							<p className='font-bold text-neutral-600'>계약/자문 실시간 검토 중 건수</p>
							<div className='flex relative'>
								<div className='absolute left-0 top-0'>
									<p className='text-5xl'>
										{(resRealtimeStatisticsData?.contract_count ?? 0) +
											(resRealtimeStatisticsData?.counsel_count ?? 0)}
									</p>
								</div>
								<div className='w-full'>
									<Chart className='h-64' option={getRealtimeReviewChart()} />
								</div>
							</div>
						</CardBox>
					</div>
				</div>
				<div>
					<CardBox className='flex flex-col gap-4 p-6'>
						<p className='font-bold text-neutral-600'>계약/자문 실시간 검토자 배정 현황</p>
						<div className='flex'>
							<div className='w-full'>
								<Chart className='h-64' option={realtimeReviewManagerChartData()} />
							</div>
						</div>
					</CardBox>
				</div>
				<div>
					<CardBox className='flex flex-col gap-4 p-6'>
						<p className='font-bold text-neutral-600'>계약/자문 신규 발생 건수 추이</p>
						<div className='w-full'>
							<Chart className='h-64' option={threeWeekNewReviewCountLineChart()} />
						</div>
					</CardBox>
				</div>
				<div>
					<CardBox className='flex flex-col gap-4 p-6'>
						{isModuleEnabled('APP_LAWSUIT') ? (
							<>
								<p className='font-bold text-neutral-600'>{`${labelText(
									'cmmn_label_lawsuit_external_delegation',
								)}`}</p>
								<div className='flex'>
									<div className='w-full'>
										<Chart className='h-64' option={realtimeLawsuitManagerChartData()} />
									</div>
								</div>
							</>
						) : (
							<>
								<p className='font-bold text-neutral-600'>계약/자문 검토 처리 건수 추이</p>
								<div className='flex'>
									<div className='w-full'>
										<Chart
											className='h-64'
											option={reviewedCountLineChart(resReviewTimesStatisticsData)}
										/>
									</div>
								</div>
							</>
						)}
					</CardBox>
				</div>
			</div>
		</AccordionDefault>
	);
}
