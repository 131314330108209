import { GlobalSearchTableRow, GlobalSearchTableSearchParams } from '../model/globalSearch';
import { sendPostPageNavigation } from './common';

export async function getGlobalSearchList(searchParams: GlobalSearchTableSearchParams) {
	const { searchCtg, currentPage, rowsPerPage, keyword, order, startDate, endDate, reSearchText } = searchParams;
	const params = {
		returnType: 'json',
		curr_page: currentPage + 1,
		search_ctg: searchCtg,
		number_per_page: rowsPerPage,
		keyword,
		order,
		startDate,
		endDate,
		reSearchText,
	};
	const res = await sendPostPageNavigation<GlobalSearchTableRow>('/api/global-search/', params);

	return res;
}
