import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatusCount } from '../../../../../api/dashboard';
import { queryKey } from '../../../../../constants/queryKey';
import { useSubsidiary } from '../../../../../context/SubsidiaryContext';
import { navigateToAdele } from '../../../../../util/commonUtils';
import StatusCardDashboard from './StatusCardDashboard';
import { useUser } from '../../../../../context/UserContext';
import SysConfigStore from "../../../../../store/common/SysConfigStore";

export default function StatusDigitalCertificationDashboardDefault() {
	const { labelText } = SysConfigStore();
	const { id: subsidiaryId } = useSubsidiary();
	const { isDigitalVertificateManageGroup, isViewer } = useUser();
	const { data: statusCount } = useQuery([queryKey.dashboard, queryKey.workStatusCount, subsidiaryId], () =>
		getMainWorkStatusCount(subsidiaryId),
	);
	const { t } = useTranslation();
	const reviewItems = [
		{
			count: statusCount?.digital_certificate?.uncompleted_count,
			label: '',
			onClick: () => navigateToAdele('/digital_certificate/digital_certificate_list_view'),
		},
	];

	const plusBtn = {
		display: true,
		classes: 'rounded-3xl',
		text: `${labelText('req_issue')}`,
		onClick: () => navigateToAdele('/digital_certificate/digital_certificate_list_view?isAutoOpen=true'),
	};

	const plusBtnItems = [
		{
			display: true,
			plusIcon: true,
			classes: 'rounded-3xl',
			text: `${labelText('req_issue')}`,
			onClick: () => navigateToAdele('/digital_certificate/digital_certificate_list_view?isAutoOpen=true'),
		},
		{
			display: isDigitalVertificateManageGroup,
			plusIcon: false,
			classes: 'rounded-3xl',
			text: `${t('cmmn_digital_certificate_management')}`,
			onClick: () => navigateToAdele('/digital_certificate/certificate_management_list_view/'),
		},
	];

	return <StatusCardDashboard title={labelText('digital_certificate')} items={reviewItems} plusBtnList={plusBtnItems} />;
}
