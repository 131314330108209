import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getGlobalSearchList } from '../../api/globalSearch';
import Header from '../../components/search/header/Header';
import TabPanel from '../../components/search/TabPanel';
import TabsSearch from '../../components/search/TabsSearch';
import CardBox from '../../components/ui/containers/CardBox';
import StickyBox from '../../components/ui/containers/StickyBox';
import { useGlobalSearchList } from '../../context/GlobalSearchContext';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import { useValidateForm } from '../../hooks/useValidateForm';

export default function ListPage() {
	const { state, search } = useLocation();
	const keyword = state?.keyword || new URLSearchParams(search).get('keyword');
	const { searchParams, setSearchParams } = useGlobalSearchList();
	const { listKeys: keys } = useGlobalSearch();
	const { data: paginationData } = useQuery(keys, () => getGlobalSearchList(searchParams));

	useEffect(() => {
		setSearchParams(prev => ({
			...prev,
			keyword,
		}));
	}, [keyword]);

	const formik = useValidateForm({
		validationSchema: {
			reSearchText: { initialValue: searchParams.reSearchText, type: 'string' },
			sorting: { initialValue: searchParams.order, type: 'string' },
			startDate: { initialValue: searchParams.startDate, type: 'string' },
			endDate: { initialValue: searchParams.endDate, type: 'string' },
			isTitle: { initialValue: true, type: 'boolean' },
			isDescription: { initialValue: true, type: 'boolean' },
			isFile: { initialValue: true, type: 'boolean' },
		},
	});

	return (
		<section className='flex flex-col gap-5 p-8 max-w-[1920px] mx-auto'>
			{paginationData && paginationData.data && paginationData.data.contractList && (
				<Header keyword={keyword} formik={formik} data={paginationData.data} />
			)}

			<section className='grid grid-cols-6 gap-6'>
				<section>
					<StickyBox position='top'>
						<CardBox className='col-span-1'>
							{paginationData && paginationData.data && paginationData.data.contractList && (
								<TabsSearch data={paginationData.data} />
							)}
						</CardBox>
					</StickyBox>
				</section>

				<CardBox className='col-span-5 px-8 py-11'>
					{paginationData && paginationData.data && paginationData.data.contractList && (
						<TabPanel data={paginationData.data} formik={formik} />
					)}
				</CardBox>
			</section>
		</section>
	);
}
