/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalSearchTableSearchParams } from '../model/globalSearch';
import { dateFormat } from '../util/dateFormat';
import { useUser } from './UserContext';

interface GlobalSearchContext {
	searchParams: GlobalSearchTableSearchParams;
	setSearchParams: React.Dispatch<React.SetStateAction<GlobalSearchTableSearchParams>>;
}

const GlobalSearchContext = createContext<GlobalSearchContext | undefined>(undefined);

export const GlobalSearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { isAdmin, isLawyer, isViewer } = useUser();
	const { state } = useLocation();

	// if (!(isAdmin || isLawyer || isViewer)) {
	// 	return <Navigate to='/' replace />;
	// }

	const [searchParams, setSearchParams] = useState<GlobalSearchTableSearchParams>({
		currentPage: 0,
		searchCtg: 'ALL',
		rowsPerPage: 10,
		keyword: '',
		order: 'relevance',
		startDate: dateFormat().subtract(1, 'year').format('YYYY.MM.DD'),
		endDate: dateFormat().format('YYYY.MM.DD'),
		reSearchText: '',
	});

	const { searchCtg, rowsPerPage, keyword, order, startDate, endDate, reSearchText } = searchParams;

	useEffect(() => {
		setSearchParams(prev => ({ ...prev, currentPage: 0 }));
	}, [searchCtg, rowsPerPage, state, keyword, order, startDate, endDate, reSearchText]);

	return (
		<GlobalSearchContext.Provider value={{ searchParams, setSearchParams }}>
			{children}
		</GlobalSearchContext.Provider>
	);
};

export function useGlobalSearchList() {
	const context = useContext(GlobalSearchContext);
	if (context === undefined) {
		throw new Error('GlobalSearch must be used within a GlobalSearchProvider');
	}

	return context;
}
