import { ReactNode } from 'react';
import PopupPreviewComment from './PopupPreviewComment';

interface IProps {
	title: string;
	className?: string;
	children: ReactNode;
	value?: string;
}

export default function ItemSearchField({ children, className = 'items-center', title, value }: IProps) {
	return (
		<article className={`${className} flex gap-1`}>
			<p className='shrink-0 min-w-[6.5rem] text-neutral-400 text-sm font-bold'>{title}</p>
			{children}
			{value && <PopupPreviewComment value={value} />}
		</article>
	);
}
