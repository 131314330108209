import { Tab } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalSearchList } from '../../context/GlobalSearchContext';
import { useUser } from '../../context/UserContext';
import { GlobalSearchTableRow } from '../../model/globalSearch';
import SysConfigStore from '../../store/common/SysConfigStore';
import AllIcon from '../ui/icons/apps/custom/sk/AllIcon';
import BoardIcon from '../ui/icons/apps/custom/sk/BoardIcon';
import ContractIcon from '../ui/icons/apps/custom/sk/ContractIcon';
import CounselIcon from '../ui/icons/apps/custom/sk/CounselIcon';
import LawsuitIcon from '../ui/icons/apps/custom/sk/LawsuitIcon';
import ReviewIcon from '../ui/icons/apps/custom/sk/ReviewIcon';
import SignIcon from '../ui/icons/apps/custom/sk/SignIcon';
import TabsCommon from '../ui/tabs/TabsCommon';

interface IProps {
	data: GlobalSearchTableRow;
}

export default function TabsSearch({ data }: IProps) {
	const { t } = useTranslation();
	const { getSysConfigValue, isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { permission } = useUser();
	const { searchParams, setSearchParams } = useGlobalSearchList();
	const tabList = [
		{
			id: 'ALL',
			count:
				data.contractCnt +
				data.counselCnt +
				data.lawsuitCnt +
				data.contractEtcCnt +
				data.noticeCnt +
				data.contractSignSealCnt,
			label: t('global_search_all'),
			icon: <AllIcon />,
			menu_bar_value: 'all',
			display: true,
		},
		{
			id: 'CONTRACT',
			count: data.contractCnt,
			label: labelText('contract'),
			icon: <ContractIcon />,
			menu_bar_value: 'contract',
			display: true,
		},
		{
			id: 'COUNSEL',
			count: data.counselCnt,
			label: t('counsel'),
			icon: <CounselIcon />,
			menu_bar_value: 'counsel',
			display: permission.DISPLAY_COUNSEL,
		},
		{
			id: 'LAWSUIT',
			count: data.lawsuitCnt,
			label: labelText('dispute'),
			icon: <LawsuitIcon />,
			menu_bar_value: 'lawsuit',
			display: permission.DISPLAY_LAWSUIT,
		},
		{
			id: 'CONTRACT_ETC',
			count: data.contractEtcCnt,
			label: labelText('etc_contract'),
			icon: <ReviewIcon />,
			menu_bar_value: 'contract_etc',
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
		},
		{
			id: 'BOARD',
			count: data.noticeCnt,
			label: t('label_notice_board'),
			icon: <BoardIcon />,
			menu_bar_value: 'board',
			display: isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF'),
		},
		{
			id: 'CONTRACT_SIGN_SEAL_PROCESS',
			count: data?.contractSignSealCnt,
			label: labelText('sign_and_seal'),
			icon: <SignIcon />,
			menu_bar_value: 'sign_and_seal',
			display: isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF'),
		},
	];

	function reorderTabList(order: string) {
		const orderArray = order.split(',').map(item => item.trim());

		const orderMap = orderArray.reduce(
			(acc, value, index) => {
				acc[value] = index;
				return acc;
			},
			{} as { [key: string]: number },
		);

		return tabList.sort((a, b) => {
			if (a.menu_bar_value === 'all') return -1;
			if (b.menu_bar_value === 'all') return 1;

			const indexA = orderMap[a.menu_bar_value] ?? Number.MAX_SAFE_INTEGER;
			const indexB = orderMap[b.menu_bar_value] ?? Number.MAX_SAFE_INTEGER;

			return indexA - indexB;
		});
	}

	const tabs = useMemo(() => {
		if (isModuleEnabled('HEADER_MENU_BAR_SORT'))
			return reorderTabList(getSysConfigValue('HEADER_MENU_BAR_SORT')).filter(({ display }) => display);
		return tabList.filter(({ display }) => display);
	}, []);
	const handleTabChange = useCallback(
		(_: SyntheticEvent, newIndex: number) => {
			const selectedTabId = tabList[newIndex]?.id;
			if (selectedTabId) {
				setSearchParams(prev => ({
					...prev,
					searchCtg: selectedTabId,
				}));
			}
		},
		[tabList],
	);

	return (
		<TabsCommon
			orientation='vertical'
			classes={{
				root: 'px-2 py-3',
				indicator: 'hidden',
			}}
			onChange={handleTabChange}
			value={tabList.findIndex(tab => tab.id === searchParams.searchCtg)}
		>
			{tabs.map(({ id, count, label, icon }) => (
				<Tab
					key={id}
					classes={{
						root: 'flex-row items-center gap-2 min-w-0 min-h-0 px-4 py-3 text-sm font-bold rounded-lg',
						iconWrapper: 'mb-0',
						selected: 'text-gray-950 bg-zinc-100',
					}}
					id={id}
					label={
						<>
							<p>{label}</p>
							<p className='ml-auto'>{count}</p>
						</>
					}
					icon={icon}
				/>
			))}
		</TabsCommon>
	);
}
