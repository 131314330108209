import { FormikValues } from 'formik';
import { useGlobalSearchList } from '../../context/GlobalSearchContext';
import { GlobalSearchTableRow } from '../../model/globalSearch';
import ContractEtcPanel from './panel/ContractEtcPanel';
import ContractPanel from './panel/ContractPanel';
import ContractSignSealPanel from './panel/ContractSignSealPanel';
import CounselPanel from './panel/CounselPanel';
import LawsuitPanel from './panel/LawsuitPanel';
import NoticePanel from './panel/NoticePanel';

interface IProps {
	data: GlobalSearchTableRow;
	formik: FormikValues;
}

export default function TabPanel({ data, formik }: IProps) {
	const { searchParams } = useGlobalSearchList();
	return (
		<section>
			<ul className='[&>li]:pt-12 [&>li:first-of-type]:pt-0'>
				{(searchParams.searchCtg === 'ALL' || searchParams.searchCtg === 'CONTRACT') && (
					<li className='grid grid-cols-10 pb-6 border-b'>
						<ContractPanel data={data} formik={formik} />
					</li>
				)}
				{(searchParams.searchCtg === 'ALL' || searchParams.searchCtg === 'COUNSEL') && (
					<li className='grid grid-cols-10 pb-6 border-b'>
						<CounselPanel data={data} formik={formik} />
					</li>
				)}
				{(searchParams.searchCtg === 'ALL' || searchParams.searchCtg === 'LAWSUIT') && (
					<li className='grid grid-cols-10 pb-6 border-b'>
						<LawsuitPanel data={data} formik={formik} />
					</li>
				)}
				{(searchParams.searchCtg === 'ALL' || searchParams.searchCtg === 'CONTRACT_ETC') && (
					<li className='grid grid-cols-10 pb-6 border-b'>
						<ContractEtcPanel data={data} formik={formik} />
					</li>
				)}
				{(searchParams.searchCtg === 'ALL' || searchParams.searchCtg === 'BOARD') && (
					<li className='grid grid-cols-10 pb-6 border-b'>
						<NoticePanel data={data} formik={formik} />
					</li>
				)}
				{(searchParams.searchCtg === 'ALL' || searchParams.searchCtg === 'CONTRACT_SIGN_SEAL_PROCESS') && (
					<li className='grid grid-cols-10 pb-6 border-b'>
						<ContractSignSealPanel data={data} formik={formik} />
					</li>
				)}
			</ul>
		</section>
	);
}
