import _ from 'lodash';

const queryKeys = [
	'dashboard',
	'contract',
	'counsel',
	'lawsuit',
	'epic',
	'main',
	'notification',
	'notificationCount',
	'companyInfoSummary',
	'dept',
	'userList', // 임시
	'appList', // 임시
	'authorizationList', // 임시
	'signList', // 날인 리스트
	'workStatusCount',
	'notice',
	'scheduleList',
	'getChartDataList',
	'loginUser',
	'subsidiary',
	'statisticsList',
	'sysConfig',
	'externalLawyerList',
	'evaluationList',
	'externalLawfirmList',
	'hardDelete',
	'tag',
	'legalTagList',
	'paperManage',
	'globalSearch',
	'sealStamp',
	'list',
	'detail',
	'noticeBoardList',
	'sealPdfData',
	'sealPdfAccess',
	'dashboardProgressCount',
	'concurrentJob'
] as const;

type QueryKey = (typeof queryKeys)[number];

export const queryKey: Record<QueryKey, QueryKey[]> = _.fromPairs(queryKeys.map(key => [key, [key]])) as Record<
	QueryKey,
	QueryKey[]
>;
