import Tabs from '@mui/material/Tabs';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatus } from '../../../../../api/dashboard';
import { queryKey } from '../../../../../constants/queryKey';
import { useSubsidiary } from '../../../../../context/SubsidiaryContext';
import { useUser } from '../../../../../context/UserContext';
import { DashboardWorkStatus } from '../../../../../model/api/dashboard';
import SysConfigStore from '../../../../../store/common/SysConfigStore';
import CardBox from '../../../../ui/containers/CardBox';
import DisputeIcon from '../../../../ui/icons/DisputeIcon';
import AllIcon from '../../../../ui/icons/apps/custom/sk/AllIcon';
import ContractIcon from '../../../../ui/icons/apps/custom/sk/ContractIcon';
import CounselIcon from '../../../../ui/icons/apps/custom/sk/CounselIcon';
import DocIcon from '../../../../ui/icons/apps/custom/sk/DocIcon';
import LawsuitIcon from '../../../../ui/icons/apps/custom/sk/LawsuitIcon';
import ReviewIcon from '../../../../ui/icons/apps/custom/sk/ReviewIcon';
import SignIcon from '../../../../ui/icons/apps/custom/sk/SignIcon';
import ListDashboard from './ListDashboard';
import TabButtonDashboard from './TabButtonDashboard';
import ClaimIcon from '../../../../ui/icons/apps/custom/sk/ClaimIcon';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	className?: string;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, className = '', ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			className={className}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export default function TabsDashboard() {
	const { isModuleEnabled, isComponentEnabled , labelText} = SysConfigStore();
	const { permission } = useUser();
	const [tab, setTab] = React.useState(0);
	const { id: subsidiaryId } = useSubsidiary();
	const { t } = useTranslation();

	const tabProps: { label: string; icon?: JSX.Element; type: string }[] = [
		{ label: '계약', icon: <ContractIcon />, type: 'CONTRACT' },
	];

	const optionalTabs: [{ label: string; icon?: JSX.Element; type: string }, boolean][] = [
		[{ label: '자문', icon: <CounselIcon />, type: 'COUNSEL' }, permission.DISPLAY_COUNSEL],
		[{ label: t('MSG_ID_1203'), icon: <LawsuitIcon />, type: 'LAWSUIT' }, permission.DISPLAY_LAWSUIT],
		[
			{ label: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')), icon: <ReviewIcon />, type: 'CONTRACT_ETC' },
			isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
		],
		[{ label: '인감', icon: <SignIcon />, type: 'STAMP' }, isModuleEnabled('CONTRACT_STAMP_MANAGE')],
		[
			{
				label: t('label_contract_sign_seal_process').replace('사인/서류', labelText('sign_and_seal')),
				icon: <SignIcon />,
				type: 'CONTRACT_SIGN_SEAL_PROCESS',
			},
			isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF') && !isComponentEnabled('CMPNT342_HIDE_MENU_SIGNSEAL_B'),
		],
		[{ label: '분쟁', icon: <DisputeIcon />, type: 'DISPUTE' }, permission.DISPLAY_DISPUTE],
		[{ label: '수발신문서함', icon: <DocIcon />, type: 'DOC_RECEIVED' }, isModuleEnabled('DOC_RECEIVED')],
		[{ label: t('MSG_ID_681'), icon: <ClaimIcon />, type: 'CLAIM' }, isModuleEnabled('MODULE_CLAIM')],
	];
	optionalTabs
		.filter(item => item[1])
		.forEach(item => {
			tabProps.push(item[0]);
		});

	if (tabProps.length > 1) {
		tabProps.unshift({ label: '전체', icon: <AllIcon />, type: 'ALL' });
	}

	const { data: workStatusList, isLoading } = useQuery(
		[queryKey.dashboard, queryKey.main, subsidiaryId, tab],
		() => getMainWorkStatus(tabProps[tab].type as 'ALL' | DashboardWorkStatus['history_type'], subsidiaryId),
		{ staleTime: 30000 },
	);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	return (
		<section className='flex gap-5'>
			<CardBox className='basis-1/4' color='bg-neutral-500'>
				<div className='p-8'>
					<h3 className='text-2xl font-bold text-white text-center'>업무현황</h3>
				</div>
				<Tabs
					classes={{
						indicator: 'right-auto left-0 w-2 bg-orange-550',
					}}
					orientation='vertical'
					variant='scrollable'
					value={tab}
					onChange={handleChange}
				>
					{tabProps.map((prop, tapIndex) => (
						<TabButtonDashboard key={prop.label} {...prop} {...a11yProps(tapIndex)} />
					))}
				</Tabs>
			</CardBox>

			<article className='basis-3/4 h-screen overflow-y-auto scrollbar'>
				{!isLoading &&
					Array.from({ length: tabProps.length }, (_, index) => index).map(index => (
						<TabPanel key={index} value={tab} index={index} className='h-full'>
							{workStatusList && workStatusList.length > 0 && <ListDashboard data={workStatusList} />}
							{(!workStatusList || workStatusList.length === 0) && (
								<div className='flex items-center justify-center h-full rounded-2xl bg-white'>
									<p className='text-neutral-400'>조회결과가 없습니다.</p>
								</div>
							)}
						</TabPanel>
					))}
			</article>
		</section>
	);
}
